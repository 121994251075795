import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import aumento1 from "../../../static/img/aumento/aumento1.jpg"
import aumento2 from "../../../static/img/aumento/aumento2.jpg"
import aumento3 from "../../../static/img/aumento/aumento3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Liquidity Enchancement Program"/>
            <Banner title="Liquidity Enchancement Program"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Client’s profile</h3>

                                <p>A publicly traded company engaged in the retail
                                    sector, which is
                                    listed on the Mexican Stock Exchange since the 90s, has noted that its liquidity has
                                    declined to the low
                                    liquidity category of the Mexican Stock Exchange’s ranking, coupled with a greatly
                                    reduced trading
                                    activity. The company decides to resume and strengthen its IR program, after
                                    entrusting
                                    it to its
                                    treasury and comptroller departments due to costs.</p>
                                <p>Through the revitalization of its IR program, and a comprehensive counseling in
                                    corporate finance and
                                    trading techniques, the company seeks to reposition its stock to a point where it
                                    can obtain the
                                    sufficient liquidity and valuation to carry out a follow-on, which in turn provides
                                    the necessary
                                    resources to move forward in implementing its core business plan.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={aumento1} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={aumento2} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandate</h3>

                                <p>For its proven capacity and experienced team, IRStrat
                                    was chosen as
                                    the best option to lead the repositioning project of the company's stock, through
                                    its
                                    tailor-made
                                    solution of Liquidity Enhancement; with the mandate to design and implement
                                    strategies
                                    of trading,
                                    issuance, prospecting and investor base broadening, as well as improving the
                                    visibility
                                    and perception
                                    of the company in the markets, with the objective of achieving a better liquidity
                                    and
                                    valuation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solution</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row mt-3 step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Strategy
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat carries out an integral
                                            assessment of all relevant
                                            factors having a bearing on the trading of the stock (IR program, trading
                                            concentration, floating
                                            composition and market participant’s perception about the issuer). With the
                                            collected information,
                                            IRStrat prepares a road map to reposition our client in the markets and put
                                            it to the forefront.
                                            Additionally, a detailed work agenda is defined, containing clear targets of
                                            trading (volume,
                                            number of operations and median transaction), visibility (investment
                                            recommendations and analyst
                                            coverage base), and valuations (P/B and EV/EBITDA). </p>
                                            <p>Our team, working with the senior management, begins to develop the
                                                company’s investment
                                                thesis, history and competitive advantage, as well as the communication
                                                style (qualitative and
                                                quantitative), and an optimal prospecting and communication
                                                strategy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Tactic
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>A detailed perception study is carried out, which
                                            allows identifying
                                            critical improvement areas, and a share ownership proxy is constructed,
                                            which outlines in depth
                                            the profile of the issuer’s current investors. With the intelligence
                                            gathered, the trading
                                            strategy is developed, that is to say, the operation of a stock buyback
                                            program, the operation of
                                            a market maker, as well as the design of other alternative treasury
                                            techniques. Hand in hand with
                                            the latter, IRStrat develops an optimal prospecting and communication
                                            strategy, which includes an
                                            intensive agenda of presentations to the marketing and analysis departments
                                            of brokerages, focused
                                            on expanding awareness and interest in the company and its stock, as well as
                                            broadening analyst
                                            coverage base.</p>
                                            <p>IRStrat performs a thorough training of our client's executive team in
                                                financial communication.
                                                All IR materials are insightfully developed to ensure optimal quality
                                                (quarterly earnings
                                                release, annual report, investor presentation, IR website and investor
                                                fact sheet). Likewise,
                                                short- and long-term guidance is developed and widely communicated.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Results
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">The valuation and trading of our client’s stock
                                            greatly improved, and
                                            bolstered its positioning in the financial markets, enabling it to execute a
                                            follow-on for 450
                                            million dollars, which in turn allowed it to enhance its stock trading,
                                            developing a virtuous
                                            cycle. After the completion of the follow-on, the company had an analyst
                                            coverage of over 10
                                            institutions, including the most influential local and international
                                            brokerages.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={aumento3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
